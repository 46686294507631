import React from "react"

import Layout from "../components/layout"
import { charte } from "../components/charte"

const GCchallenge = () => (
  <Layout page={"GCchallenge"}>
    <h1 align="center" style={{color: charte.primary}}>GreenCom CIRB Challenge</h1>
    <p/>
    <p/>
    <p>The aim of this first year GreenCom Challenge is to measure the carbon impact of each team within the CIRB by taking into account energy expenditure, waste production and travel.
    We are currently assessing and looking for proper tools in order to do so.</p>
  </Layout>
)

export default GCchallenge